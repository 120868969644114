export function fileWindowApi(onUpload,acceptFile = "image/*") {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = acceptFile;
    fileInput.style.display = "none";
    document.body.appendChild(fileInput);
    fileInput.removeEventListener("change", (event) => onFileChange(event,onUpload)); // First, remove any existing listener
    fileInput.addEventListener("change", (event) => onFileChange(event,onUpload));
    fileInput.click();
}


function onFileChange(event,onUpload) {
    const file = event.target.files[0];
    toBase64(file).then((base64) => {
      onUpload({ file, base64 });
    });
  }
const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
    });
};
